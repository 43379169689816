@font-face {
    font-family: 'Telegraf';
    src: url('./assests/fonts/PPTelegraf-Regular.otf')format('truetype');
}

@font-face {
    font-family: 'Inter-regular';
    src: url('./assests/fonts/Inter_28pt-Regular.ttf')format('truetype');
}



.inter_input{
    font-family: 'Inter-regular', sans-serif !important;
}


body{
    font-family: 'Telegraf', sans-serif !important;
    background-color: black !important;
}


@media (max-width: 1024px){
.coming_soon_image{
    margin-top: 196px;
}
}